<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <message-links-comp></message-links-comp>
                <div class="board_list">
                    <div class="logo_panel">
                        <div style="display:flex;flex-wrap:nowrap;justify-content: left;background-color: #515151;box-sizing: border-box;padding: 10px">
                            <div style="width: 80px"><img
                                    src="../../assets/images/icon/item_icon_warning.png" style="width: 50px;" alt="">
                            </div>
                            <div style="font-size: 10px;text-align: left;">
                                <p>상대방을 비방 또는 욕설을 포함한 쪽지 작성 시 예고 없이 차단됩니다.</p>
                                <p>쪽지 전송은 <span style="color: #f4c600">아이템샵 → 쪽지</span> 아이템 구매 후 이용이 가능합니다.</p>
                                <p>쪽지 전송 후 취소가 불가하오니 신중하게 작성해 주시기 바랍니다.</p>
                            </div>
                        </div>
                        <h4 class="pay_title">쪽지쓰기</h4>
                        <div class="message_box">
                            <div class="m_box_item" style="">
                                <div class="m_box_title">
                                    보유쪽지 아이템
                                </div>
                                <div class="m_box_content">
                                    <div class="m_box_content_item">
                                        <div class="m_box_content_item_title">쪽지 100개</div>
                                        <div style="cursor: pointer" @click="useMessagePack(100)">
                                            <img src="../../assets/images/item/oneplay/item_shop_5.png">
                                        </div>
                                        <div class="m_box_content_item_title">보유건수
                                            {{$store.state.userInfo.itemsendmessage100pack}}개
                                        </div>
                                    </div>
                                    <div class="m_box_content_item">
                                        <div class="m_box_content_item_title">쪽지 500개</div>
                                        <div style="cursor: pointer" @click="useMessagePack(250)">
                                            <img src="../../assets/images/item/oneplay/item_shop_6.png">
                                        </div>
                                        <div class="m_box_content_item_title">보유건수
                                            {{$store.state.userInfo.itemsendmessage250pack}}개
                                        </div>
                                    </div>
                                    <div class="m_box_content_item noborder">
                                        <div style="line-height: 30px;margin-top: 35%;font-size: 16px;color: #f4c600">
                                            전송가능
                                        </div>
                                        <div style="line-height: 30px">{{$store.state.userInfo.itemsendmessage|comma}}
                                            건
                                        </div>
                                        <div>
                                            <router-link to="/item_shop" tag="button" class="btn03"
                                                         style="width: 80%;padding: 10px 0;color: #f4c600">구매하기
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m_box_item">
                                <div class="m_box_title">
                                    메세지 작성
                                </div>
                                <div style="width: 100%;text-align: left;padding: 10px;margin-top: 10px;border-bottom: 4px solid #f4c600">
                                    <p style="color: #f4c600"><i class="fa fa-star"></i>회원별 전송</p>
                                    <p>받을회원의 닉네임을 적어주세요</p>
                                    <p>여려회원일 경우 `,`으로 분리해주세요(예:홍길동,임꺽정)</p>
                                    <input type="text" v-model="message.nicknameStr"
                                           style="width: 100%;margin-top: 10px;height: 35px;color: #1a4050;text-align: center"
                                           placeholder="여려회원일 경우 `,`으로 분리해주세요(예:홍길동,임꺽정)">
                                    <div class="send_buttons"
                                         style="display: flex;justify-content: space-evenly;width: 100%;margin-top: 10px">
                                        <button class="btn03" style="width: 47%;padding: 10px 0;color: #f4c600"
                                                @click="sendMessageByNinames">쪽지전송
                                        </button>
                                    </div>
                                </div>
                                <div style="width: 100%;text-align: left;padding: 10px;margin-top: 10px;border-bottom: 4px solid #f4c600"
                                     class="clearfix">
                                    <p style="color: #f4c600"><i class="fa fa-star"></i>랜덤 전송</p>
                                    <p>랜덤회원(최대250명) 수 입력, 글 작성 후 랜덤발송 클릭</p>
                                    <input type="text" v-model="message.randomCount"
                                           style="width: 50%;margin-top: 10px;height: 35px;color: #1a4050;text-align: center;float: left">
                                    <div class="send_buttons"
                                         style="display: flex;justify-content: space-evenly;width: 50%;margin-top: 10px;float: right;">
                                        <button class="btn03" style="width: 47%;padding: 10px 0;color: #f4c600;"
                                                @click="sendMessageRandom">랜덤전송
                                        </button>
                                    </div>
                                </div>

                                <div class="m_box_content">
                                    <quill-editor class="editor"
                                                  ref="contentEditor"
                                                  v-model="message.content"
                                                  :options="editorOption"
                                                  @blur="onEditorBlur($event)"
                                                  @focus="onEditorFocus($event)"
                                                  @ready="onEditorReady($event)"
                                                  @change="onEditorChange($event)">
                                    </quill-editor>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import Pagination from "../../components/pagenation/Pagination";
    import {
        checkedMessageAll,
        checkedMessageById,
        deleteMessageAll,
        deleteMessageById,
        getMessageList, move2box, sendMessageByNinames, sendMessageRandom, useMessagePack
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import MessageLinksComp from "../../components/links/MessageLinksComp";
    import {RECEIVE_USER_INFO} from "../../store/mutation-types";

    export default {
        name: "MessageInBox",
        components: {
            MessageLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeftBarComp, Pagination, SubTitle, RightBarComp
        },
        data() {
            return {
                sportsConst,
                message: {'content': '', 'nicknameStr': '', 'randomCount': ''},
                editorOption: {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                            [{font: []}],
                        ], //기능바 세팅
                    },
                    placeholder: '내용을 입력하세요', //提示
                    readyOnly: false, //是否只读
                    theme: 'bubble', //skin snow/bubble
                    syntax: false, //문법체크
                },
            }
        },
        methods: {
            useMessagePack(type) {

                this.$swal({
                    title: '아이템을 사용하시겠습니가?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        useMessagePack(type).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '사용완료',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.dispatch('actionUserInfo')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'warning',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })

            },

            sendMessageByNinames() {
                let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).+$/
                if (!(regx.test(this.message.content))) {
                    //if(false){
                    this.$swal({
                        title: '한글이 포함된 내용으로 작성해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                if (this.message.nicknameStr.trim() === '') {
                    this.$swal({
                        title: '회원 닉네임을 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                sendMessageByNinames(this.message).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '쪽지 전송이 완료되었습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$store.dispatch('actionUserInfo')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'warning',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            sendMessageRandom(){
                //sendMessageRandom
                let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).+$/
                if (!(regx.test(this.message.content))) {
                    //if(false){
                    this.$swal({
                        title: '한글이 포함된 내용으로 작성해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                if (this.message.randomCount.trim() === '') {
                    this.$swal({
                        title: '랜덤 회원수를 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                sendMessageRandom(this.message).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '랜덤쪽지 전송이 완료되었습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$store.dispatch('actionUserInfo')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'warning',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

            pageChange(page) {
                this.pageNum = page
                this.initMessageList()
            },
            onEditorBlur(editor) {
            },
            onEditorFocus(editor) {
            },
            onEditorReady(editor) {
            },
            onEditorChange(editor) {
                this.content = editor.html;
            },
        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>
    .board_list .logo_panel{
        padding-top: 0px;
    }
    .board_list .message_box {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .message_box .m_box_item {
        width: 49%;
        background: #414141;
        box-shadow: -1px 1px 5px rgb(0 0 0 / 30%);
    }

    .message_box .m_box_item .m_box_title {
        height: 44px;
        line-height: 44px;
        text-align: left;
        box-sizing: border-box;
        background: linear-gradient(to bottom, rgba(61, 61, 61, 1) 0%, rgba(54, 54, 54, 1) 100%);
        color: #f8f1e3;
        font-size: 14px;
        padding-left: 10px;
    }

    .message_box .m_box_content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;
        flex-wrap: nowrap;
        padding: 15px 0;
    }

    .m_box_content img {
        height: 120px;
    }

    .m_box_content .m_box_content_item {
        padding: 20px;
        border: 1px solid #9f9fa1;
        width: 32%;
    }

    .m_box_content .m_box_content_item_title {
        padding: 10px;
    }

    .editor {
        width: 100%;
        height: 120px;
        background-color: #f8f1e3;
        color: #0c0e0e;
        margin: 20px
    }

    @media screen and (max-width: 1024px) {
        .message_box .m_box_item {
            width: 100% !important;
        }

        .m_box_content img {
            height: 50px !important;
        }
    }
</style>